import React from "react";
import PropTypes from "prop-types";
import { GlobalStyles } from "twin.macro";

import Header from "./header";
import Footer from "./footer";

// https://www.gatsbyjs.com/docs/recipes/styling-css/#using-google-fonts
// https://fontsource.org/fonts/pacifico
//import "@fontsource/open-sans"; // Defaults to weight 400.
import "@fontsource/cabin/400.css"; // Weight 400
import "@fontsource/cabin/500.css";

// https://www.gatsbyjs.com/docs/recipes/pages-layouts/#creating-a-layout-component
// https://www.gatsbyjs.com/docs/how-to/routing/layout-components/
// https://www.gatsbyjs.com/plugins/gatsby-plugin-layout/
export default function Layout({ children, ...rest }) {
  return (
    <div tw="text-secondary flex flex-col min-h-screen" {...rest}>
      <Header />
      <GlobalStyles />
      <main>{children}</main>
      <Footer />
    </div>
  );
}

// https://github.com/facebook/react/issues/5143#issuecomment-662150906
Layout.propTypes = {
  children: PropTypes.any,
};
