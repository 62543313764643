import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, Link, graphql } from "gatsby";
import "twin.macro";

export default function Footer() {
  const { site } = useStaticQuery(query);
  const { tel, tel2, domain } = site.siteMetadata;

  return (
    <footer id="footer" tw="bg-brandgreen-100 text-secondary mt-auto">
      <div tw="container mx-auto p-12 items-center sm:flex">
        <div tw="sm:w-1/2 leading-loose flex sm:block justify-center">
          <table>
            <tbody>
              <tr>
                <td tw="text-secondary">Mobil</td>
                <td tw="pl-6 font-medium">
                  <a href={"tel:" + tel}>{tel}</a>
                </td>
              </tr>
              <tr>
                <td tw="text-secondary">Telefon</td>
                <td tw="pl-6 font-medium">
                  <a href={"tel:" + tel2}>{tel2}</a>
                </td>
              </tr>
              <tr>
                <td tw="text-secondary">E-Mail</td>
                <td tw="pl-6 font-medium">
                  <a href={"mailto:info@" + domain}>{"info@" + domain}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div tw="sm:w-1/2 mt-8 text-center sm:mt-0 sm:text-right">
          <Link to="/">
            <StaticImage
              tw="inline-block"
              src="../images/logo.svg"
              placeholder="blurred"
              alt="A & F Garten- und Landschaftsbau Logo"
              width={200}
            />
          </Link>
        </div>
      </div>
      <div tw="text-center p-6 border-t border-brandgreen-400">
        <Link tw="hover:text-primary hover:underline" to="/datenschutz">
          Datenschutz
        </Link>
        <span tw="inline-block mx-3 text-secondary">•</span>
        <Link tw="hover:text-primary hover:underline" to="/impressum">
          Impressum
        </Link>
      </div>
    </footer>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        tel
        tel2
        domain
      }
    }
  }
`;
